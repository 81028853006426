import { createRouter, createWebHashHistory } from 'vue-router';
const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/index.vue'),
	},
	// {
	// 	path: '/about',
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
	// },
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
